// Generated by Framer (2372734)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/s0y5Rymtd";

const enabledGestures = {zP2tSu61V: {hover: true}};

const cycleOrder = ["zP2tSu61V"];

const variantClassNames = {zP2tSu61V: "framer-v-1q08xdj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, J0UW3IKsV: title ?? props.J0UW3IKsV ?? "Buy Template", t4OlGevL5: link ?? props.t4OlGevL5} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, t4OlGevL5, J0UW3IKsV, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zP2tSu61V", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-JpuJU", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={t4OlGevL5} openInNewTab><motion.a {...restProps} className={`${cx("framer-1q08xdj", className)} framer-wz4bao`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zP2tSu61V"} ref={ref} style={{...style}} {...addPropertyOverrides({"zP2tSu61V-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1i4mbrj"} data-styles-preset={"s0y5Rymtd"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>Buy Template</motion.p></React.Fragment>} className={"framer-1y0st85"} layoutDependency={layoutDependency} layoutId={"HltsAWFWk"} style={{"--extracted-r6o4lv": "var(--token-2d0196d5-4c11-460d-91d6-2fb81e415efd, rgb(196, 240, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={J0UW3IKsV} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1i4mbrj"} data-styles-preset={"s0y5Rymtd"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>Buy Template</motion.p></React.Fragment>} className={"framer-1kea940"} layoutDependency={layoutDependency} layoutId={"UJj2Vz0a1"} style={{"--extracted-r6o4lv": "var(--token-2d0196d5-4c11-460d-91d6-2fb81e415efd, rgb(196, 240, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={J0UW3IKsV} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-JpuJU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JpuJU .framer-wz4bao { display: block; }", ".framer-JpuJU .framer-1q08xdj { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 16px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-JpuJU .framer-1y0st85, .framer-JpuJU .framer-1kea940 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-JpuJU .framer-v-1q08xdj .framer-1q08xdj { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JpuJU .framer-1q08xdj { gap: 0px; } .framer-JpuJU .framer-1q08xdj > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-JpuJU .framer-1q08xdj > :first-child { margin-top: 0px; } .framer-JpuJU .framer-1q08xdj > :last-child { margin-bottom: 0px; } }", ".framer-JpuJU.framer-v-1q08xdj.hover .framer-1q08xdj { justify-content: flex-end; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 75
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"He2I78AuV":{"layout":["auto","fixed"]}}}
 * @framerVariables {"t4OlGevL5":"link","J0UW3IKsV":"title"}
 */
const Framero3QzQRm6k: React.ComponentType<Props> = withCSS(Component, css, "framer-JpuJU") as typeof Component;
export default Framero3QzQRm6k;

Framero3QzQRm6k.displayName = "buy";

Framero3QzQRm6k.defaultProps = {height: 16, width: 75};

addPropertyControls(Framero3QzQRm6k, {t4OlGevL5: {title: "Link", type: ControlType.Link}, J0UW3IKsV: {defaultValue: "Buy Template", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framero3QzQRm6k, [...sharedStyle.fonts])